<template>
	<div class="board" v-if="statsLoaded">
		<div class="board__head">
			<h2 class="board__title h5">Total Sales</h2>
			<BaseSelect :options="options" :default="currentMonthNumber" @input="setOption"/>
		</div>
		<div class="board__body">
			<div class="total-sales">
				<div class="total-sales__chart">
					<Doughnut :data="chartData" :options="chartOptions" />
					<div class="total-sales__total-value">
						<div class="total-value">
							<span>$ {{ format(this.stats.only_fans_sales + this.stats.tings_sales) }}</span>
							<span>Total Sales</span>
						</div>
					</div>
				</div>
				<div class="total-sales__legend">
					<div class="total-sales__item">
						<div class="sales-src">
							<div class="sales-src__img">
								<img src="@/assets/img/onlyfans.png" alt="onlyfans">
							</div>
							<div class="sales-src__text">
								<div class="sales-src__name">OnlyFans</div>
								<div class="sales-src__value">$ {{ format(this.stats.only_fans_sales) }}</div>
							</div>
						</div>
					</div>
					<div class="total-sales__item">
						<div class="sales-src">
							<div class="sales-src__img">
								<img src="@/assets/img/tingz-2.png" alt="tingz">
							</div>
							<div class="sales-src__text">
								<div class="sales-src__name">Para</div>
								<div class="sales-src__value">$ {{ format(this.stats.tings_sales) }}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
  import { Doughnut } from 'vue-chartjs'
  import { Chart as ChartJS, Tooltip, Legend, ArcElement } from 'chart.js'
  import BaseSelect from '@/components/BaseSelect'
  import format from '@/services/format-number'

  ChartJS.register(Tooltip, Legend, ArcElement);

  export default {
    name: 'BoardTotalSales',


    components: {
      BaseSelect,
      Doughnut,
    },


    props: {
      stats: {
        type: Object,
        default: () => ({}),
        required: true,
      },
    },


    data() {
      return {
        monthList: [],
        options: [],
        currentData: 0,
        currentMonthNumber: 0,
        statsLoaded: false,

        chartData: {},
        chartOptions: {},
      }
    },


    created() {
      // this.options = this.monthList.slice(0, this.currentMonthNumber + 1);
      // const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(now);
      // const month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(now);
      // this.currentData = `${year}-${month}-01`;
      // this.selectedMonth = month;
      const now = new Date();
      for(let i = 0; i < 12; i++) {
        const year = new Date(now.getFullYear(), now.getMonth() - i).getFullYear();
        const month = new Date(now.getFullYear(), now.getMonth() - i).getMonth() + 1;
        const monthName = new Date(now.getFullYear(), now.getMonth() - i).toLocaleString('en', { month: 'long' });
        this.options.push({ value: year + '-' + month + '-' + '01', name: monthName + ' ' + year })
      }
      this.options.reverse();
      this.currentMonthNumber = this.options[11].value;
      if (this.statsLoaded) {
        this.setChartData()
      }
    },


    watch: {
      stats(value) {
        if (value) {
          this.statsLoaded = true;
          this.setChartData()
        }
      }
    },


    methods: {
      format(number) {
        return format(number)
      },
      setOption(value) {
        console.log(value)
        this.$emit('input', value)
      },
      setChartData() {
        this.chartData = {
          labels: ['OnlyFans', 'Tingz'],
            datasets: [
            {
              backgroundColor: ['#97B2FF', '#FDD7F0'],
              borderWidth: 0,
              data: [this.stats.only_fans_sales, this.stats.tings_sales],
              spacing: 4,
              borderRadius: 6,
              rotation: 210
            }
          ]
        };

        this.chartOptions = {
          plugins: {
            legend: {
              display: false,
            },
          },
          responsive: true,
            cutout: 60,
        }
      }
    }
  }
</script>

<style scoped lang="less">
	@import "@/assets/style/variables.less";

	.total-sales {
		display: flex;
		gap: 20px;
		flex-direction: column;
		align-items: center;

		@media @desktop {
			flex-direction: row;
			flex-wrap: wrap;
		}

		&__chart {
			height: 212px;
			width: 212px;
			padding: 16px;
			background-color: #F7F9FB;
			border-radius: 100%;
			position: relative;
		}

		&__total-value {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}

		&__legend {
			display: flex;
			flex-direction: row;
			width: 100%;
			max-width: 300px;

			@media @desktop {
				flex-direction: column;
				gap: 40px;
				width: unset;
				max-width: unset;
			}
		}

		&__item {
			flex: 0 0 50%;
		}
	}

	.total-value {
		display: flex;
		flex-direction: column;
		align-items: center;

		& span {
			display: block;

			&:nth-child(1) {
				font-family: 'Clash Display';
				font-weight: 700;
				font-size: 22px;
			}

			&:nth-child(2) {
				font-size: 12px;
				color: #4D4E53;
			}
		}
	}

	.sales-src {
		display: flex;
		gap: 12px;

		&__img {
			width: 40px;
			height: 40px;
			flex: 0 0 40px;
			//background-color: #1D2029;
			overflow: hidden;
			border-radius: 12px;

			& img {
				object-fit: contain;
				width: 100%;
				height: 100%;
			}
		}

		&__text {

		}

		&__name {
			font-size: 12px;
			margin-bottom: 4px;
		}

		&__value {
			font-family: 'Clash Display';
			font-weight: 700;
			font-size: 16px;
		}
	}
</style>